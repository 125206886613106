/* eslint-disable array-callback-return */
/* eslint-disable no-sequences */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getVaidationErrors';
import Buttom from '../../forms/Buttom';
import InputSearch from '../../forms/InputSearch';

import Modal from '../Modal';
import { useAuth } from '../../../hooks/auth';
import { saveManageSubjectsLog } from '../../../services/logs';

import {
  getListProviderSubjects,
  getListProviderSubjectsByTitle,
  getProviderSubjectItem,
  getSellersRents,
  postCreateSellerSubject,
  postCreateSellerSubjectItem,
} from '../../../services/api';

import {
  Container,
  ContainerFlex,
  Bottom,
  Both,
  Subject,
  SubjectContent,
  SubjectOptions,
  EmptyTitle,
} from './styles';
import ReactSelect from '../../forms/ReactSelect';
import { IconBookmarkYellow } from '../../../styles/icons';
import Checkbox from '../../forms/Checkbox';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  clearFilter: () => void;
}
interface IProviderRented {
  id: string;
  providerId: string;
  sellerId: string;
  paymentMethod: string;
  status: string;
  provider: {
    name: string;
    companyName: string;
    imgLogoUrl: string;
  };
}
interface IRentedProvider {
  label: string;
  value: string;
}
interface ISubject {
  subject_id: string;
  name: string;
  itemsCount: string;
}

const ModalImportSubject: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  clearFilter,
}) => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const [listOfProviders, setListOfProviders] = useState<
    Array<IRentedProvider>
  >([{ value: '', label: 'Selecione algum fornecedor' }]);

  const [checks, setChecks] = useState<Array<string>>([]);
  const [selectedProvider, setSelectedProvider] = useState('');
  const [providerSelectedSubjects, setProviderSelectedSubjects] =
    useState<Array<ISubject>>();

  // SEARCH ===========
  const [search, setSearch] = useState('');

  useEffect(() => {
    const load = async (): Promise<void> => {
      setLoading(true);

      try {
        if (user.type.includes('seller')) {
          const { data } = await getSellersRents();

          data
            .filter(item => item.status === 'active')
            .map(item => {
              setListOfProviders(oldState => [
                ...oldState,
                {
                  label: item.provider.companyName,
                  value: item.providerId,
                },
              ]);
            });
        }
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description: 'Algo inesperado aconteceu.',
        });
      }

      setLoading(false);
    };

    load();
  }, [addToast, user]);

  const handleSelectedProvider = useCallback((selectedProviderItem: string) => {
    setSelectedProvider(selectedProviderItem);
  }, []);

  const handleCheck = useCallback(check => {
    setChecks(check);
  }, []);

  useEffect(() => {
    const loadProviderSubjects = async (): Promise<void> => {
      if (selectedProvider) {
        const { data: res } = await getListProviderSubjects(selectedProvider);
        setProviderSelectedSubjects(res);
      }
    };
    loadProviderSubjects();
  }, [selectedProvider]);

  useEffect(() => {
    const loadProviderSubjects = async (): Promise<void> => {
      if (search) {
        const { data: res } = await getListProviderSubjectsByTitle(
          selectedProvider,
          search,
        );
        setProviderSelectedSubjects(res);
      }
    };
    loadProviderSubjects();
  }, [search]);

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const promisesResponse = await Promise.all(
          checks.map(async id => {
            return getProviderSubjectItem(id);
          }),
        );

        promisesResponse.forEach(async ({ data: dataProviderSubject }) => {
          const {
            data: { subject_id },
          } = await postCreateSellerSubject({
            name: dataProviderSubject.name,
            sellerId: user.seller?.id,
          });

          await Promise.all(
            dataProviderSubject.subjectItems.map(item => {
              return postCreateSellerSubjectItem({
                itemId: item.itemId,
                subjectId: subject_id,
                itemContentId: item.itemContentId,
              });
            }),
          );
          // saveManageSubjectsLog({
          //   userId: user.id,
          //   action: 'imported_subject',
          //   itemId: null,
          //   itemContentId: null,
          //   subjectId: subject_id,
          // });
          saveManageSubjectsLog({
            userId: user.id,
            sellerId: user.seller?.id || '',
            action: 'imported_subject',
            subjectId: subject_id,
          });
        });

        addToast({
          title: 'Disciplina cadastrada com sucesso!',
          type: 'success',
        });

        setIsOpen();
        clearFilter();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          title: 'Ops...',
          type: 'error',
          description: 'Algo inesperado aconteceu.',
        });
      }

      setLoading(false);
    },
    [addToast, setIsOpen, user, clearFilter, checks],
  );
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} overflow="hidden">
      <Container>
        <h1>Importar Disciplina</h1>
        <ContainerFlex>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <div style={{ margin: '40px 0', height: 'auto' }}>
              {listOfProviders && (
                <ReactSelect
                  name="type"
                  options={listOfProviders}
                  placeholder="Digite ou selecione algum fornecedor"
                  handleValue={handleSelectedProvider}
                />
              )}
              {providerSelectedSubjects && (
                <InputSearch
                  name="search"
                  onChange={e => setSearch(e.target.value)}
                  placeholder="Pesquisar"
                  value={search}
                />
              )}
              {providerSelectedSubjects?.length !== 0 &&
              providerSelectedSubjects ? (
                providerSelectedSubjects.map((item, i) => (
                  <Subject className={`subject-${i}`}>
                    <SubjectContent>
                      <h3>{item.name}</h3>
                      <p className="units">
                        <IconBookmarkYellow />{' '}
                        <strong>{item.itemsCount}</strong> unidades
                      </p>
                    </SubjectContent>
                    <SubjectOptions>
                      <Checkbox
                        name="check"
                        handleCheck={handleCheck}
                        checks={checks}
                        id={item.subject_id}
                      />
                    </SubjectOptions>
                  </Subject>
                ))
              ) : (
                <EmptyTitle>
                  Não há disciplinas registradas nesse fornecedor...
                </EmptyTitle>
              )}
            </div>

            <Both />
            <button type="submit" hidden>
              submeter
            </button>
          </Form>
        </ContainerFlex>
        <Bottom>
          <Buttom
            name="cancelar"
            type="button"
            onClick={() => setIsOpen()}
            label="Cancelar"
            outline
          />
          <Buttom
            name="salvar"
            onClick={() => {
              formRef.current?.submitForm();
            }}
            label="Salvar"
            isLoading={loading}
          />
        </Bottom>
      </Container>
    </Modal>
  );
};

export default ModalImportSubject;
