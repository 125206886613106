/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { BeatLoader } from 'react-spinners';
import Switch from 'react-switch';
import { useHistory } from 'react-router-dom';
import Checkbox, { CheckBoxAll } from '../../components/forms/Checkbox';
import ChoiceEllipse from '../../components/forms/ChoiceEllipse';
import InputSearch from '../../components/forms/InputSearch';
import Tabs from '../../components/template/Tabs';
import {
  IconBuilding,
  IconCheck,
  IconIosClose,
  IconMail,
  IconUserAlt,
  IconUserCircle,
} from '../../styles/icons';

import {
  AddButton,
  Bagde,
  ImportButton,
  NameContent,
  OptionLeft,
  OptionRight,
  Options,
  PrimaryButton,
  TableContent,
  Title,
} from '../../components/template/TemplateManager/styles';
import {
  Container,
  Content,
  Actions,
  ActionsContent,
  MoreInfo,
  MoreInfoItem,
  PlansDiv,
} from './styles';

import { subTypeProvider } from '../../data/subTypeProvider';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import {
  filterProvider,
  putChangeStatusProvider,
  putChangePlanProvider,
} from '../../services/api';
import { saveManageProvidersLog } from '../../services/logs';
import useLazyEffect from '../../hooks/useLazyEffect';
import AlertConfirm from '../../components/template/AlertConfirm';
import ReactSelect from '../../components/forms/ReactSelect';

interface IProviders {
  id: string;
  name: string;
  email: string;
  cnpj: string;
  cpf: string;
  companyName: string;
  status:
    | 'active'
    | 'inative'
    | 'exclused'
    | 'review'
    | 'approved'
    | 'rejected'
    | 'pending'
    | 'draft';
  typePerson: 'Profissional Liberal' | 'Instituição de Ensino' | 'Fornecedor';
  condition: string;
  isPrimium: boolean;
}

const statusProviderObj = {
  active: 'Ativo',
  inative: 'Inativo',
  exclused: 'Excluído',
  review: 'Análise',
  approved: 'Aprovado',
  rejected: 'Reprovado',
  pending: 'Análise',
  draft: 'draft',
};

const statusOptions = [
  { id: '1', value: 'all', label: 'Todos' },
  { id: '2', value: 'review', label: 'Em análise' },
  { id: '3', value: 'active', label: 'Aprovados' },
  { id: '4', value: 'rejected', label: 'Reprovados' },
];

const Providers: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const firstLoad = useRef(false);
  const history = useHistory();

  const [providers, setProviders] = useState<IProviders[]>([]);
  const [providersInitalCopy, setProvidersInitalCopy] = useState<IProviders[]>(
    [],
  );
  const [checks, setChecks] = useState<Array<string>>([]);
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState('tab-02');

  const [selected, setSelected] = useState('');
  const [select, setSelect] = useState('all');
  const [status, setStatus] = useState('all');
  const [search, setSearch] = useState('');

  const providerSubtype = useMemo(() => {
    return subTypeProvider.map(item => {
      return {
        label: item.label,
        value: item.id,
      };
    });
  }, []);

  // Identify url userType
  const urlUserType = useMemo(() => {
    const type = [
      { id: '1', name: 'adm' },
      { id: '2', name: 'diretor' },
    ];
    let res = type.find(item => item.id === user.type)?.name;
    if (!res) {
      res = 'provider';
    }
    return res;
  }, [user.type]);

  const statusOptionsParse = useMemo(() => {
    return statusOptions.map(item => {
      if (item.value === 'all') {
        return {
          ...item,
          label: `${item.label} (${providersInitalCopy.length})`,
        };
      }
      return {
        ...item,
        label: `${item.label} (${
          providersInitalCopy.filter(provider => provider.status === item.value)
            .length
        })`,
      };
    });
  }, [providersInitalCopy]);

  const handleSetProvider = useCallback((provider: IProviders[]) => {
    setProviders(
      provider.map(item => {
        return {
          ...item,
          isPrimium: item.condition === 'premium',
        };
      }),
    );
  }, []);

  // Frist load
  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        setLoading(true);
        const { data: res } = await filterProvider({
          orderByCreatedAt: 'DESC',
        });

        handleSetProvider(res);
        setProvidersInitalCopy(res);
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [urlUserType, addToast, handleSetProvider]);

  // Change Tabs
  useLazyEffect(() => {
    const load = async (): Promise<void> => {
      try {
        setLoading(true);
        switch (currentTab) {
          case 'tab-01':
            const { data: resOrder } = await filterProvider({
              orderByCompanyName: 'ASC',
            });
            handleSetProvider(resOrder);

            break;
          default:
            const { data: res } = await filterProvider({
              orderByCreatedAt: 'DESC',
            });

            handleSetProvider(res);
            break;
        }
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description: 'Algo de inesperado aconteceu',
        });
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [currentTab, urlUserType, addToast, handleSetProvider]);

  // Change Staus
  useLazyEffect(() => {
    const load = async (): Promise<void> => {
      try {
        setLoading(true);
        if (status === 'all') {
          const { data: resStatus } = await filterProvider({
            orderByCreatedAt: 'DESC',
          });
          handleSetProvider(resStatus);

          return;
        }
        const { data: resFilter } = await filterProvider({
          status,
          typePerson: select === 'all' ? undefined : select,
        });
        handleSetProvider(resFilter);
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description: 'Algo de inesperado aconteceu',
        });
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [status, handleSetProvider]);

  // Change Select
  useLazyEffect(() => {
    const load = async (): Promise<void> => {
      try {
        setLoading(true);
        if (select === 'all') {
          const { data: res } = await filterProvider({
            orderByCreatedAt: 'DESC',
          });

          handleSetProvider(res);
          return;
        }

        const { data: resFilter } = await filterProvider({
          typePerson: select,
          status: status === 'all' ? undefined : status,
          name: search,
        });
        handleSetProvider(resFilter);
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description: 'Algo de inesperado aconteceu',
        });
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [select, handleSetProvider]);

  // Search by name and email
  useEffect(() => {
    if (!firstLoad.current) {
      firstLoad.current = true;
      return;
    }

    try {
      setLoading(true);
      const timer = setTimeout(() => {
        filterProvider({
          companyName: search,
          orderByCompanyName: 'ASC',
        }).then(res => {
          if (!res.data) {
            return;
          }
          handleSetProvider(res.data);
        });
      }, 1000);
      return () => clearTimeout(timer);
    } catch (error) {
      addToast({
        title: 'Ops...',
        type: 'error',
        description: 'Algo de inesperado aconteceu',
      });
    } finally {
      setLoading(false);
    }
  }, [search, addToast, urlUserType, handleSetProvider]);

  const handleDropdown = useCallback((id: string) => {
    setSelected(state => (state === id ? '' : id));
  }, []);

  const handleCheck = useCallback(check => {
    setChecks(check);
  }, []);

  const handleTab = useCallback((id: string) => {
    setCurrentTab(id);
  }, []);

  const handleSelect = useCallback((value: string) => {
    setSelect(value);
  }, []);

  const handleStatus = useCallback((value: string) => {
    setStatus(value);
  }, []);

  const handleCheckPremium = useCallback(
    async (provider_id: string) => {
      try {
        await putChangePlanProvider({
          provider_id,
          condition:
            providers.find(item => item.id === provider_id)?.isPrimium === true
              ? 'basic'
              : 'premium',
        });

        const newProvider = providers.map(provider => {
          return {
            ...provider,
            isPrimium:
              provider.id === provider_id
                ? !provider.isPrimium
                : provider.isPrimium,
          };
        });
        setProviders(newProvider);

        saveManageProvidersLog({
          userId: user.id,
          action: 'updated_condition_provider',
          providerId: provider_id,
        });

        addToast({
          title: 'Sucesso!',
          description: 'Condição do provider alterada com sucesso!',
          type: 'success',
        });
      } catch (error) {
        addToast({
          title: 'Ops...',
          description: 'Algo inesperado aconteceu',
          type: 'error',
        });
      }
    },
    [providers, addToast],
  );

  const handleChangeStatus = useCallback(
    async ({
      ids,
      statusProvider,
    }: {
      ids: Array<string>;
      statusProvider: string;
    }) => {
      const confirm = await AlertConfirm({
        title: `Tem certeza que deseja alterar o status para ${
          statusProvider === 'active' ? 'Ativo' : 'Reprovado'
        }?`,
        text: 'Esta ação pode ser desfeita',
      });

      if (!confirm) {
        return;
      }

      try {
        await putChangeStatusProvider({
          ids,
          status: statusProvider,
        });

        const { data: res } = await filterProvider({
          orderByCreatedAt: 'DESC',
        });

        handleSetProvider(res);
        setChecks([]);
        setCurrentTab('tab-02');
        setSelect('all');
        setSearch('');
        setStatus('all');

        saveManageProvidersLog({
          userId: user.id,
          action: 'updated_provider_status',
          status: statusProvider,
          providerId: ids,
        });

        addToast({
          title: 'Sucesso!',
          type: 'success',
          description: 'Status alterado com sucesso!',
        });
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description: 'Algo de inesperado aconteceu',
        });
      }
    },
    [addToast, handleSetProvider],
  );

  const renderProviders = useCallback(() => {
    return (
      <table>
        <thead>
          <tr className="no-cursor no-hover">
            <th>
              <CheckBoxAll
                ids={providers.map(item => item.id)}
                handleCheckAll={handleCheck}
              />
            </th>
            <th>Provider</th>
            <th>Status</th>
            <th>Tipo de Provider</th>
            <th>Premium</th>
            <th>
              <meta />
            </th>
          </tr>
        </thead>
        <tbody>
          {providers.map((provider: IProviders) => (
            <React.Fragment key={`${provider.id}-tab-01`}>
              <tr className={`${provider.id === selected ? `dropdown` : ``}`}>
                <td className="no-cursor">
                  <Checkbox
                    name="check"
                    handleCheck={handleCheck}
                    checks={checks}
                    id={`${provider.id}`}
                  />
                </td>

                <td onClick={() => handleDropdown(provider.id)}>
                  <NameContent>
                    <IconUserCircle />
                    <span>{provider.companyName}</span>
                  </NameContent>
                </td>
                <td onClick={() => handleDropdown(provider.id)}>
                  <Bagde
                    className={`${provider.status === 'active' && 'active'} ${
                      provider.status === 'rejected' && 'danger'
                    }`}
                  >
                    {statusProviderObj[provider.status]}
                  </Bagde>
                </td>
                <td onClick={() => handleDropdown(provider.id)}>
                  {provider.typePerson}
                </td>
                <td>
                  <Switch
                    onChange={(checked, event) => {
                      event.stopPropagation();
                      handleCheckPremium(provider.id);
                    }}
                    checked={!!provider.isPrimium}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={20}
                    width={36}
                    onColor="#F6C866"
                    offColor="#DAD7D7"
                  />
                </td>
                <td width={120} className="arrow no-cursor">
                  <Actions>
                    <ActionsContent className={`show ${provider.status}`}>
                      <button
                        type="button"
                        className="white"
                        onClick={() =>
                          handleChangeStatus({
                            ids: [provider.id],
                            statusProvider: 'rejected',
                          })
                        }
                      >
                        <IconIosClose />
                      </button>
                      <button
                        type="button"
                        className="primary"
                        onClick={() =>
                          handleChangeStatus({
                            ids: [provider.id],
                            statusProvider: 'active',
                          })
                        }
                      >
                        <IconCheck width={12} />
                      </button>
                    </ActionsContent>
                    <PlansDiv hidden={provider.status === 'review'}>
                      <PrimaryButton
                        onClick={() =>
                          history.push(
                            `/dashboard/providers/plan/${provider.id}`,
                          )
                        }
                      >
                        Editar plano
                      </PrimaryButton>
                    </PlansDiv>
                  </Actions>
                </td>
              </tr>
              <tr
                className={`${
                  provider.id !== selected ? `hidden` : `slideUp`
                } more-info`}
              >
                <td />
                <td colSpan={5}>
                  <MoreInfo>
                    <MoreInfoItem>
                      <IconUserAlt />
                      <div>
                        <span>Responsável:</span> <br />
                        {provider.name}
                      </div>
                    </MoreInfoItem>
                    <MoreInfoItem>
                      <IconMail />
                      <div>
                        <span>E-mail:</span> <br />
                        {provider.email}
                      </div>
                    </MoreInfoItem>
                    <MoreInfoItem>
                      <IconBuilding />
                      <div>
                        {provider.typePerson === 'Profissional Liberal' ? (
                          <>
                            <span>CPF:</span> <br />
                            {provider.cpf}
                          </>
                        ) : (
                          <>
                            <span>CNPJ:</span> <br />
                            {provider.cnpj}
                          </>
                        )}
                      </div>
                    </MoreInfoItem>
                  </MoreInfo>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    );
  }, [
    providers,
    handleCheck,
    selected,
    checks,
    handleDropdown,
    handleCheckPremium,
    handleChangeStatus,
    history,
  ]);
  return (
    <Container className="slideDown">
      <Title>
        <h1>Providers</h1>
        <ChoiceEllipse
          name="status"
          itens={statusOptionsParse}
          defaultValue={status}
          handleStatus={handleStatus}
        />
      </Title>
      <Content>
        <Options>
          <OptionRight>
            <div className="search">
              <InputSearch
                name="search"
                placeholder="Pesquisar"
                onChange={e => setSearch(e.target.value)}
              />
            </div>
            <div className="select">
              <ReactSelect
                name="status"
                options={[
                  { label: 'Tipos de Provider', value: 'all' },
                  ...providerSubtype,
                ]}
                handleValue={handleSelect}
                defaultValue={select}
              />
            </div>
          </OptionRight>
          <OptionLeft className={`${checks.length === 0 && `hidden`}`}>
            <ImportButton
              onClick={() =>
                handleChangeStatus({
                  statusProvider: 'rejected',
                  ids: checks,
                })
              }
            >
              <IconIosClose /> Reprovar
            </ImportButton>
            <AddButton
              onClick={() =>
                handleChangeStatus({
                  statusProvider: 'active',
                  ids: checks,
                })
              }
            >
              <IconCheck /> Aprovar
            </AddButton>
          </OptionLeft>
        </Options>

        <TableContent>
          <Tabs
            itens={[
              { id: 'tab-01', label: 'A-Z' },
              { id: 'tab-02', label: 'Mais Recentes' },
            ]}
            defaultValue={currentTab}
            handleTab={handleTab}
          />
          {loading ? (
            <div className="loading">
              <BeatLoader size={16} />
            </div>
          ) : (
            <div className={`content-tabs ${loading ? `hidden` : `slideUp`}`}>
              {renderProviders()}
            </div>
          )}
        </TableContent>
      </Content>
    </Container>
  );
};

export default Providers;
