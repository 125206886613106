/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useCallback, useEffect, useState } from 'react';
import { FiFilter, FiFrown } from 'react-icons/fi';
import { BeatLoader } from 'react-spinners';
import { format } from 'date-fns';

import { generateId } from '../../../utils/generateId';
import Api, { filterUsers, getProviderTeam } from '../../../services/api';
import {
  getLogsToReport,
  getLogsToReportWithoutUser,
  logTypes,
} from '../../../services/logs';

import { AlertWrapper, Container, Filters, FiltersWrapper } from './styles';

import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { UsersTypes } from '../../../services/auth';
import Input from '../../../components/forms/Input';
import Select from '../../../components/forms/SelectForm';

interface ITableProps {
  userName?: string;
  action: string;
  createdAt: string;
}

interface ISellersProps {
  id: string;
  name: string;
  status: string;
}

interface IParams {
  user_id?: string;
  start_date: string;
  end_date: string;
  log_type?: string;
}

interface IOptionsProps {
  label: string;
  value: string | number;
  status?: string;
}

interface ILogActions {
  userName?: string;
  action: string;
  createdAt: string;
}

const LogsReport: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const [sellers, setSellers] = useState<ISellersProps[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [dataSource, setDataSource] = useState<ITableProps[]>([]);
  const [allLogTypes, setLogTypes] = useState<IOptionsProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    // Obtém o primeiro dia do mês
    const firstDayOfMonth = format(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      'yyyy-MM-dd',
    );

    // Obtém a data atual
    const today = format(new Date(), 'yyyy-MM-dd');

    setStartDate(firstDayOfMonth);
    setEndDate(today);
  }, []);

  const getData = useCallback(
    async (params?: IParams) => {
      try {
        // const allLogType = [];
        const allLogType = await logTypes();

        allLogType.sort(function (a, b) {
          if (a.label < b.label) {
            return -1;
          }
          return 0;
        });

        let AccessDataSource: ILogActions[] = [];

        if (!params) {
          // setLoading(true);

          console.log(startDate, endDate);
          // AccessDataSource = await getLogsToReport(params);
        }

        if (params && params.user_id) {
          setLoading(true);

          console.log(params);
          AccessDataSource = await getLogsToReport(params);
        }

        if (params && !params.user_id) {
          setLoading(true);

          console.log(params);

          const availableUsers: any = [];
          if (user.type === 'seller') {
            const { data: allUsers } = await Api.get('sellers/my-team');

            availableUsers.push(user.id);
            allUsers.forEach(string => {
              availableUsers.push(string.user.id);
            });
          }

          if (user.type === 'provider') {
            const { data: allUsers } = await getProviderTeam();

            allUsers.forEach(string => {
              availableUsers.push(string.id);
            });
          }

          AccessDataSource = await getLogsToReportWithoutUser(
            params,
            availableUsers,
          );
        }

        if (user.type === 'admin') {
          const { data: allUsers } = await filterUsers({
            orderDate: 'DESC',
            type: 'seller',
            providerId: user.provider?.id,
          });

          user.status = 'active';
          allUsers.push({ user });
          setSellers([...allUsers]);
        }

        if (user.type === 'seller') {
          const { data: allUsers } = await Api.get('sellers/my-team');
          user.status = 'active';
          allUsers.push({ user });
          setUsers([...allUsers]);
        }

        if (user.type === 'provider') {
          const { data: allUsers } = await getProviderTeam();
          user.status = 'active';
          allUsers.push({ user });

          setSellers([...allUsers]);
        }

        setDataSource([...AccessDataSource]);
        setLogTypes([...allLogType]);
      } catch (error) {
        console.error(error);

        addToast({
          type: 'error',
          title: 'Atenção!',
          description:
            'Não foi possível realizar esta operação, tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [user, startDate, endDate, addToast],
  );

  useEffect(() => {
    if (startDate && endDate) {
      getData({ start_date: startDate, end_date: endDate }); // Agora os valores estarão disponíveis
    }
  }, [startDate, endDate, getData]);

  const SelectOptionsUsers = users.map(newUser => ({
    label: newUser.user.name,
    value: newUser.user.id,
    status: newUser.user.status === 'active' ? 'Ativo' : 'Suspenso',
  }));

  const SelectOptionsSellers = sellers.map(seller => ({
    label: seller.name,
    value: seller.id,
    status: seller.status === 'active' ? 'Ativo' : 'Suspenso',
  }));

  return (
    <Container>
      <header>
        <h1>Relatório de Logs</h1>
        <span>{user.name}</span>
      </header>

      <Filters>
        <FiltersWrapper onSubmit={data => getData(data)}>
          <button type="submit">
            <FiFilter />
            Filtrar
          </button>

          <Input label="Data Inicial*" required name="start_date" type="date" />
          <Input label="Data Final*" required name="end_date" type="date" />

          {user.type === UsersTypes.admin && (
            <Select
              name="user_id"
              placeholder="Usuário"
              options={SelectOptionsSellers}
            />
          )}

          {user.type === UsersTypes.provider && (
            <Select
              name="user_id"
              placeholder="Usuário"
              options={SelectOptionsSellers}
            />
          )}

          {SelectOptionsUsers.length > 0 && (
            <Select
              name="user_id"
              placeholder="Usuário"
              options={SelectOptionsUsers}
            />
          )}

          <Select name="log_type" placeholder="Ação" options={allLogTypes} />
        </FiltersWrapper>
      </Filters>
      {loading ? (
        <div style={{ textAlign: 'center', padding: '30px' }}>
          <BeatLoader size={16} />
        </div>
      ) : (
        <div className={`content-tabs ${loading ? `hidden` : `slideUp`}`}>
          <table>
            <thead>
              <tr>
                <th>Descrição</th>
                <th>Responsável</th>
                <th style={{ width: '400px' }}>Data do Evento</th>
              </tr>
            </thead>
            <tbody>
              {dataSource.map(register => (
                <tr key={generateId()}>
                  <td>{register.action || <b>(Não Informado)</b>}</td>
                  <td>{register.userName}</td>
                  <td>{register.createdAt}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {!loading && dataSource.length === 0 && (
        <AlertWrapper>
          <FiFrown size={50} />
          Sem dados para apresentação
        </AlertWrapper>
      )}
    </Container>
  );
};

export { LogsReport };
