import { firebase, firebaseLogs } from './firebaseConnection';
import 'firebase/firestore';
import { getShowUser } from './api';

interface IBaseLog {
  userId: string;
  action: string;
  sellerId?: string;
}

interface ICreateUserLog extends IBaseLog {
  newUserEmail: string;
}

interface IManageUserLog extends IBaseLog {
  status?: string;
  modifiedUserEmail?: string;
  modifiedUserId: string | string[];
}

interface ICreateSubjectLog extends IBaseLog {
  newSubjectName: string;
}

interface IManageSubjectLog extends IBaseLog {
  itemId?: string | null;
  itemContentId?: string | null;
  subjectId: string;
}

interface ICreateCourseLog extends IBaseLog {
  newCourseName: string;
}

interface IManageCourseLog extends IBaseLog {
  courseId: string;
}

interface IManageDelegationUnitLog {
  userId: string;
  action: string;
  curatorId: string;
  reviewerId: string;
  unityId: string | string[];
}

interface IManageUnitLog {
  userId: string;
  action: string;
  status: string;
  unityId: string | string[];
}

interface IManagePlanLog {
  userId: string;
  action: string;
  planId?: string;
  planName: string;
}

interface IManagePlanPriceLog {
  userId: string;
  action: string;
  providerId: string;
  planId: string;
  value: string;
}

interface IManageProviderLog {
  userId: string;
  action: string;
  status?: string;
  providerId?: string | string[];
}

interface IManageSellerLog extends IBaseLog {
  status?: string;
}

interface IFilterLogs {
  user_id?: string;
  start_date: string;
  end_date: string;
  log_type?: string;
  availableUsers?: string[];
  availableUser?: string[] | string;
}

interface IOptions {
  label: string;
  value: string | number;
  status?: string;
}

interface ILogAction {
  userName?: string;
  action: string;
  createdAt: string;
}

interface IUser {
  id: string;
  name: string;
  email: string;
  status: string;
  type: string;
  createdAt: string;
  updatedAt: string;
}

interface ITimeLog {
  seconds: number;
  nanoseconds: number;
}

interface ILog {
  action: string;
  createdAt: ITimeLog;
  userId: string;
}

interface IManageUnitEditorLog {
  action: string;
  userId: string;
  unityId?: string | string[] | null;
}

const dataBaseLogs = firebaseLogs.firestore();
const Timestamp = firebase.firestore.FieldValue.serverTimestamp();

let logCollection =
  process.env.REACT_APP_ENV === 'staging' ? 'logs-staging' : 'logs';

if (process.env.NODE_ENV === 'development') {
  logCollection = 'logs-staging';
}

export const getLogActionTranslation = (data: string): string => {
  const logActionTranslations: Record<string, string> = {
    logged: 'Efetuou Login',
    logged_out: 'Efetuou Logout',
    updated_provider_status: 'Alterou o status do Fornecedor',
    updated_condition_provider: 'Alterou a condição do Fornecedor',
    update_user_status: 'Alterou o Status do Usuário',
    update_unit_status: 'Alterou o Status da Unidade',
    update_subject_name: 'Alterou o Nome da Disciplina',
    removed_subject: 'Removeu uma Disciplina',
    added_item_to_cart: 'Adicionou Item ao Carrinho',
    updated_seller_status: 'Alterou o Status do Vendedor',
    generated_lms_key: 'Gerou Nova LMS Key',
    updated_profile: 'Atualizou o Perfil',
    removed_course: 'Removeu um Curso',
    payment_requested: 'Realizou o Pedido de Dados de Pagamento',
    imported_subject: 'Importou Disciplina',
    imported_course: 'Importou Curso',
    edit_user: 'Editou um Usuário',
    updated_course: 'Alterou um Curso',
    added_item_to_subject: 'Adicionou uma Unidade à Disciplina',
    updated_delegate_unity: 'Alterou a Delegação da Unidade',
    delegate_unity: 'Delegou uma Unidade',
  };

  return logActionTranslations[data] || 'Outro';
};

const saveLog = (action: string, data: Record<string, any>): void => {
  dataBaseLogs.collection(logCollection).add({
    ...data,
    action,
    createdAt: Timestamp,
  });
};

export const logTypes = async (): Promise<IOptions[]> => {
  const types: IOptions[] = [{ label: 'Todos', value: 'all' }];
  const allLogsTypes = await dataBaseLogs
    .collection('log-types')
    .doc('aQgWRdu1lDe435bKDpdw')
    .get()
    .then(doc => {
      return doc.data();
    });
  if (allLogsTypes) {
    allLogsTypes.type.forEach(type => {
      types.push({ label: getLogActionTranslation(type), value: type });
    });
  }

  return types;
};

export const convertCreatedAtLog = (data): string => {
  const convertedDate = new Date(data.seconds * 1000).toLocaleString();

  return convertedDate;
};

export const convertEndDateProcessed = async (EndDate): Promise<string> => {
  const EndDateDivided = EndDate.split('-');
  const dayEndDate =
    +EndDateDivided[2] < 9
      ? `0${+EndDateDivided[2] + 1}`
      : +EndDateDivided[2] + 1;

  const EndDateProcessed =
    await `${EndDateDivided[0]}-${EndDateDivided[1]}-${dayEndDate}T23:59:59.000Z`;
  return EndDateProcessed;
};

export const separate = (itens): string[] => {
  const arrayUsersAvailable: any = [];
  for (let i = 0; i < itens.length; i += 10) {
    arrayUsersAvailable.push(itens.slice(i, i + 10));
  }
  return arrayUsersAvailable;
};

// FUNCTIONS PARA OBTENÇÃO DE DADOS
export const saveGenericLog = (data: IBaseLog): void => {
  const { userId, sellerId, action } = data;

  dataBaseLogs.collection(logCollection).add({
    userId,
    sellerId: sellerId || null,
    action,
    createdAt: Timestamp,
  });
};

export const saveCreateNewUserLog = (data: ICreateUserLog): void => {
  saveLog('created_new_user', data);
};

export const saveManageUserLog = (data: IManageUserLog): void => {
  const action = data.action || 'edit_user';
  saveLog(action, data);
};

export const saveCreateNewSubjectLog = (data: ICreateSubjectLog): void => {
  saveLog('created_new_subject', data);
};

export const saveManageSubjectsLog = (data: IManageSubjectLog): void => {
  saveLog(data.action, data);
};

export const saveCreateNewCourseLog = (data: ICreateCourseLog): void => {
  saveLog('created_new_course', data);
};

export const saveManageCoursesLog = (data: IManageCourseLog): void => {
  saveLog(data.action, data);
};

export const saveManageDelegationUnitsLog = (
  data: IManageDelegationUnitLog,
): void => {
  saveLog(data.action, data);
};

export const saveManageUnitsLog = (data: IManageUnitLog): void => {
  saveLog(data.action, data);
};

export const saveManagePlansLog = (data: IManagePlanLog): void => {
  saveLog(data.action, data);
};

export const saveManagePlanPriceLog = (data: IManagePlanPriceLog): void => {
  saveLog(data.action, data);
};

export const saveManageProvidersLog = (data: IManageProviderLog): void => {
  saveLog(data.action, data);
};

export const saveManageSellersLog = (data: IManageSellerLog): void => {
  saveLog(data.action, data);
};

export const saveManageUnitsFromEditorLog = (
  data: IManageUnitEditorLog,
): void => {
  saveLog(data.action, data);
};

const getLogsFromDatabase = async (
  userFilter: string | string[],
  startDate: string,
  endDate: string,
  logType?: string,
): Promise<ILog[]> => {
  let logsQuery = dataBaseLogs
    .collection(logCollection)
    .where('userId', Array.isArray(userFilter) ? 'in' : '==', userFilter)
    .where('createdAt', '>=', new Date(startDate))
    .where('createdAt', '<=', new Date(await convertEndDateProcessed(endDate)))
    .orderBy('createdAt', 'desc');

  if (logType && logType !== 'all') {
    logsQuery = logsQuery.where('action', '==', logType);
  }

  return logsQuery
    .get()
    .then(querySnapshot => querySnapshot.docs.map(doc => doc.data() as ILog))
    .catch(error => {
      throw new Error(`Error getting logs: ${error}`);
    });
};

// export const getLogsByFilter = async (data: IFilterLogs): Promise<ILog[]> => {
//   const { start_date, end_date, user_id, log_type } = data;
//   const dataFound: any = [];

//   const EndDateProcessed = await convertEndDateProcessed(end_date);

//   let logsFound: any = '';
//   if (log_type && log_type !== 'all') {
//     logsFound = await dataBaseLogs
//       .collection(logCollection)
//       .where('userId', '==', user_id)
//       .where('createdAt', '>=', new Date(start_date))
//       .where('createdAt', '<=', new Date(`${EndDateProcessed}`))
//       .where('action', '==', log_type)
//       .get()
//       .then(querySnapshot => {
//         querySnapshot.forEach(async doc => {
//           await dataFound.push(doc.data());
//         });

//         return dataFound;
//       })
//       .catch(error => {
//         throw new Error(`Error getting documents: ${error}`);
//       });
//   } else {
//     logsFound = await dataBaseLogs
//       .collection(logCollection)
//       .where('userId', '==', user_id)
//       .where('createdAt', '>=', new Date(start_date))
//       .where('createdAt', '<=', new Date(`${EndDateProcessed}`))
//       .get()
//       .then(async querySnapshot => {
//         querySnapshot.forEach(async doc => {
//           await dataFound.push(doc.data());
//         });

//         return Promise.all(await dataFound);
//       })
//       .catch(error => {
//         throw new Error(`Error getting documents: ${error}`);
//       });
//   }

//   return Promise.all(await logsFound);
// };

export const getLogsByFilter = async (data: IFilterLogs): Promise<ILog[]> => {
  return getLogsFromDatabase(
    data.user_id!,
    data.start_date,
    data.end_date,
    data.log_type,
  );
};

export const getLogsByFilterWithoutUser = async (
  data: IFilterLogs,
): Promise<ILog[]> => {
  const usersToFind = data.availableUsers ?? data.availableUser!;
  return getLogsFromDatabase(
    usersToFind,
    data.start_date,
    data.end_date,
    data.log_type,
  );
};

export const getUserPropById = async (userId: string): Promise<IUser> => {
  return getShowUser(userId)
    .then(({ data: userFind }) => userFind)
    .catch(() => ({ name: 'Não encontrado' } as IUser));
};

export const getLogsToReport = async (
  data: IFilterLogs,
): Promise<ILogAction[]> => {
  const logsFound = await getLogsByFilter(data);
  const usersCache: Record<string, string> = {}; // Cache para evitar múltiplas chamadas à API

  const resultLogsFound = logsFound.map(async log => {
    if (!usersCache[log.userId]) {
      const user = await getUserPropById(log.userId);
      usersCache[log.userId] = user.name;
    }

    return {
      userName: usersCache[log.userId],
      action: getLogActionTranslation(log.action),
      createdAt: convertCreatedAtLog(log.createdAt),
    };
  });

  return Promise.all(resultLogsFound);
};

export const getLogsToReportWithoutUser = async (
  data: IFilterLogs,
  availableUsers: string[],
): Promise<ILogAction[]> => {
  const { start_date, end_date, log_type } = data;
  const usersCache: Record<string, string> = {}; // Cache para evitar múltiplas chamadas à API

  let logsFound: ILog[] = [];

  if (availableUsers.length <= 10) {
    logsFound = await getLogsByFilterWithoutUser({
      start_date,
      end_date,
      log_type,
      availableUsers,
    });
  } else {
    const arrayAvailableUsers = await separate(availableUsers);
    const usersLogs = await Promise.all(
      arrayAvailableUsers.map(async availableUser =>
        getLogsByFilterWithoutUser({
          start_date,
          end_date,
          log_type,
          availableUser,
        }),
      ),
    );
    logsFound = usersLogs.flat(); // Une os arrays de logs
  }

  // Processamento dos logs
  const processedLogs = logsFound.map(async log => {
    if (!usersCache[log.userId]) {
      const user = await getUserPropById(log.userId);
      usersCache[log.userId] = user.name;
    }

    return {
      userName: usersCache[log.userId],
      action: getLogActionTranslation(log.action),
      createdAt: convertCreatedAtLog(log.createdAt),
    };
  });

  return Promise.all(processedLogs);
};
