import styled, { css } from 'styled-components';

export const Holder = styled.div`
  display: flex;
  width: 100%;
  & > main {
    width: 100%;
  }
  & > aside {
    width: 300px;
  }
`;

export const Container = styled.div``;

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface ContentProps {
  readonly Tiny?: boolean;
}

export const Content = styled.div<ContentProps>`
  ${props =>
    props.Tiny &&
    css`
      height: 300px;
      overflow: hidden;
      position: relative;
      margin-top: -10px;
    `}
  margin-top: 50px;
  h2 {
    color: var(--gray);
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 10px;
  }
  section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    padding-top: 20px;
    article {
      background: #ffffff;
      border: 1px solid var(--quaternary);
      border-bottom: 2px solid var(--aurea);
      border-radius: 5px 5px 0px 0px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 30px 20px;
      main {
        width: 100%;
        h1 {
          font-size: 1rem;
        }
        p {
          font-size: 0.875rem;
          color: var(--gray);
          margin-top: 5px;
        }
      }
      footer {
        a {
          font-weight: bold;
          color: var(--tertiary);
          font-size: 0.75rem;
          border: 1px solid var(--quaternary);
          border-radius: 5px;
          padding: 5px 20px;
          transition: all 0.3s;
          &:hover {
            background: var(--gray-border);
            text-decoration: none;
          }
        }
        button {
          font-weight: bold;
          color: var(--tertiary);
          background: var(--aurea);
          border: none;
          font-size: 0.75rem;
          border-radius: 5px;
          padding: 5px 18.5px;
          transition: all 0.3s;
          margin-top: 10px;
          &:hover {
            background: var(--hover);
          }
        }
        .rating {
          margin-top: 10px;
          font-size: 14px;

          .space {
            margin-right: 5px;
          }

          .stars {
            color: orange;
            font-size: 16px;
          }

          .count {
            color: #1a0dab;
            font-size: 13px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .grow {
    position: absolute;
    bottom: 0px;
    display: none;
    width: 100%;
    height: 100px;
    background: var(--white);
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fcfafa+0,fcfafa+100&0+0,1+56,1+100 */
    background: -moz-linear-gradient(
      top,
      rgba(252, 250, 250, 0) 0%,
      rgba(252, 250, 250, 1) 56%,
      rgba(252, 250, 250, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(252, 250, 250, 0) 0%,
      rgba(252, 250, 250, 1) 56%,
      rgba(252, 250, 250, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(252, 250, 250, 0) 0%,
      rgba(252, 250, 250, 1) 56%,
      rgba(252, 250, 250, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00fcfafa', endColorstr='#fcfafa',GradientType=0 ); /* IE6-9 */

    ${props =>
      props.Tiny &&
      css`
        display: flex;
        justify-content: center;
        align-items: center;
      `}

    button {
      background: var(--aurea);
      border: none;
      font-weight: bold;
      padding: 15px 20px;
      border-radius: 5px;
      font-size: 1rem;
      color: var(--dark);
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        margin-right: 10px;
      }
    }
  }
  .loading {
    width: 100%;
    padding: 20px;
    text-align: center;
    & > div {
      display: inline-block;
      opacity: 0.5;
    }
  }
`;

export const Title = styled.div`
  margin-bottom: -20px;
  h1 {
    margin-right: 24px;
  }
  div.bread-crumb {
    ul {
      display: flex;
      list-style: none;
      li {
        font-weight: bold;
        color: var(--aurea);
        a {
          font-weight: normal;
          color: var(--tertiary);
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
          &:after {
            content: '>';
            display: inline-block;
            margin: 0 10px;
            font-size: 0.75rem;
          }
        }
        &:last-child {
          text-decoration: underline;
        }
      }
    }
  }
  div.provider {
    background: #ffffff;
    border: 1px solid var(--quaternary);
    padding: 30px;
    margin: 20px 0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    img {
      width: 115px;
      height: 115px;
      object-fit: cover;
      margin-right: 30px;
      border-radius: 50%;
    }
    & > div {
      width: 100%;
      h1 {
        font-size: 1.875rem;
        margin-bottom: 10px;
      }
      & > div {
        display: flex;
        align-items: flex-end;
        & > div {
          width: 50%;
        }
        & > div:first-child {
          p.units {
            margin-top: 20px;
            font-size: 0.75rem;
            color: var(--aurea);
            strong {
              font-weight: bold;
            }
          }
        }
        & > div:last-child {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          p {
            color: var(--gray);
            strong {
              font-weight: bold;
            }
            span {
              font-size: 0.625rem;
            }
          }
          a {
            font-size: 0.9rem;
            display: block;
            width: 140px;
            padding: 15px;
            border: none;
            color: var(--dark);
            background: var(--gray-opacity);
            border-radius: 5px;
            font-weight: bold;
            transition: background 400ms;
            margin-left: 20px;
            &:hover {
              text-decoration: none;
              background: var(--gray-border);
            }
          }
          button {
            display: block;
            width: 140px;
            padding: 15px;
            border: none;
            color: var(--dark);
            background: var(--aurea);
            border-radius: 5px;
            font-weight: bold;
            transition: background 400ms;
            margin-left: 20px;
            &:hover {
              text-decoration: none;
              background: var(--hover);
            }
          }
          a,
          button {
            &.rented {
              opacity: 0.5;
              background: var(--white);
              border: 1px solid var(--gray-border);
              transition: opacity 0.3s;
              text-align: center;
              &:hover {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
`;

export const RightSide = styled.aside`
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 20px;
  background: #ffffff;
  border-left: 1px solid #e7e5e5;
  & > div {
    padding: 25px;
    & + div {
      border-top: 1px solid #e0dede;
    }
    h2 {
      font-size: 1.875rem;
      font-weight: bold;
      margin-bottom: 10px;
    }
    h3 {
      font-weight: bold;
      color: var(--gray);
    }
    .radioContainer {
      padding: 10px 5px;
      .radioContent {
        display: block;
        position: relative;
        color: var(--gray);
        cursor: pointer;
        padding-left: 35px;
        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }
        .radiomark {
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          background-color: #ffffff;
          border-radius: 50%;
          border: 2px solid #c6c2c2;
          box-sizing: border-box;
          transition: all 100ms;
        }
        &:hover input ~ .radiomark {
          background-color: #c6c2c2;
        }
        & input:checked ~ .radiomark {
          border: 0;
          background-color: var(--aurea);
        }
      }
      label + label {
        margin: 10px;
      }
    }
    .checkboxContainer {
      padding: 20px 5px;
      .checkboxContent {
        display: block;
        position: relative;
        color: var(--gray);
        cursor: pointer;
        padding-left: 35px;
        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          background-color: #ffffff;
          border-radius: 3px;
          border: 2px solid #c6c2c2;
          box-sizing: border-box;
          transition: all 100ms;
          &:after {
            content: '';
            position: absolute;
            display: none;

            left: 6px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
        &:hover input ~ .checkmark {
          background-color: #c6c2c2;
        }
        & input:checked ~ .checkmark {
          border: 0;
          background-color: var(--aurea);
          &:after {
            display: block;
          }
        }
      }
      label + label {
        margin-top: 10px;
      }
    }
    .input-range {
      margin-bottom: 20px;
      margin-top: 30px;
      .input-range__track--active {
        background: var(--aurea);
      }
      .input-range__slider {
        background: #ffffff;
        border: 1px solid var(--quaternary);
      }
      .input-range__label-container {
        left: 0;
      }
      .input-range__label--min,
      .input-range__label--max {
        display: none;
      }
      .input-range__track {
        & > .input-range__slider-container + .input-range__slider-container {
          .input-range__label-container {
            left: inherit;
            right: 0;
            top: 40px;
            position: absolute;
          }
        }
      }
    }
  }
  button {
    background: none;
    border: none;
    width: 100%;
    text-decoration: underline;
    color: var(--gray);
    margin-bottom: 50px;
  }
`;

export const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-top: 8px;
  font-size: 14px;
`;
export const RatingScore = styled.span`
  font-weight: bold;
`;

export const Stars = styled.div`
  display: flex;
  span {
    color: orange;
    font-size: 18px;
  }
`;

export const RatingCount = styled.span`
  color: #1a0dab;
  cursor: pointer;
`;
