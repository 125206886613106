/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';

import { Container, RowGroup, File, Check } from './styles';

interface IModalProps {
  isOpen: boolean;
}

const ModalLatePaymentTerms: React.FC<IModalProps> = ({ isOpen }) => {
  return (
    <Container>
      <div>
        <br />
        Aviso Importante
        <br />
        <p>
          A PLATAFORMA ÁUREA informa que o acesso à plataforma contratada está
          condicionado ao cumprimento das obrigações financeiras previstas no
          contrato. Caso haja atraso superior a 10 (dez) dias no pagamento, o
          acesso à plataforma e seus conteúdos poderá ser temporariamente
          suspenso até a regularização da pendência. Essa medida visa manter o
          equilíbrio contratual e garantir a continuidade dos serviços. Para
          evitar interrupções, pedimos que os pagamentos sejam feitos dentro do
          prazo estabelecido. Se houver dúvidas ou necessidade de negociação,
          nossa equipe está disponível para atendimento pelos canais habituais.
        </p>
        <p>
          <small>
            Obs.: Este informativo trata apenas do procedimento em questão e não
            indica que sua Instituição esteja inadimplente.
          </small>
        </p>
        <p>
          Atenciosamente,
          <br />
          EQUIPE PLATAFORMA ÁUREA
        </p>
        <br />
        <Check>
          <label>
            <input type="checkbox" id="acceptCheckbox" />

            <span>
              {' '}
              Confirmo leitura e ciência do Termo de inadimplência da Plataforma
              Educacional Áurea.
            </span>
          </label>
        </Check>
        {/* <img src={LogoYellow} alt="Logo" width={164} /> */}
      </div>
      {/* <Bottom>
        <Buttom label="Cancelar" outline onClick={() => setIsOpen()} />
        <Buttom label="Salvar" />
      </Bottom> */}
    </Container>
  );
};

export default ModalLatePaymentTerms;
