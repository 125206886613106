import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { putUpdateUser, putUpdateUserSeller } from '../../../services/api';
import getValidationErrors from '../../../utils/getVaidationErrors';
import Buttom from '../../forms/Buttom';
import Input from '../../forms/Input';
import ReactSelectForm from '../../forms/ReactSelectForm';
import { saveManageUserLog } from '../../../services/logs';

import Modal from '../Modal';

import { Container, Bottom, RowGroup, Row, Both } from './styles';

interface IEditUser {
  id: string;
  name: string;
  type: string;
  email: string;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  userType: {
    value: string;
    label: string;
  }[];
  userId: IEditUser;
  handleInitialState(): void;
}

const ModalEditUser: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  userType,
  userId,
  handleInitialState,
}) => {
  const { addToast } = useToast();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Campo Obrigatório'),
          email: Yup.string()
            .required('Campo Obrigatório')
            .email('Email inválido'),
          type: Yup.string().required('Campo Obrigatório'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        if (user.type === 'seller') {
          await putUpdateUserSeller(
            { name: data.name, email: data.email, type: data.type },
            userId.id,
          );
        } else {
          await putUpdateUser(
            { name: data.name, email: data.email, type: data.type },
            userId.id,
          );
        }

        // await saveManageUserLog({
        //   userId: user.id,
        //   action: 'edit_user',
        //   status: null,
        //   modifiedUserEmail: data.email,
        //   modifiedUserId: userId.id,
        // });

        saveManageUserLog({
          userId: user.id,
          action: 'edit_user',
          modifiedUserEmail: data.email,
          modifiedUserId: userId.id,
          sellerId: user.seller?.id || '',
        });

        addToast({
          title: 'Sucesso!',
          type: 'success',
          description: 'Usuário editado com sucesso!',
        });
        handleInitialState();
        setIsOpen();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          title: 'Ops...',
          type: 'error',
          description: 'Algo inesperado aconteceu.',
        });
      } finally {
        setLoading(false);
      }
    },
    [user.type, addToast, handleInitialState, setIsOpen, userId.id],
  );
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <h1>Editar Usuário</h1>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={userId}>
          <RowGroup>
            <Input
              name="name"
              label="Nome:"
              type="text"
              placeholder="John Doe"
            />
            <ReactSelectForm
              name="type"
              options={[{ value: '', label: 'Todos os Usuário' }, ...userType]}
              placeholder="Todos os usuários"
            />
          </RowGroup>
          <Both />
          <Row>
            <Input
              name="email"
              label="Email:"
              type="text"
              placeholder="email@dominio.com.br"
            />
          </Row>
          <button type="submit" hidden>
            submeter
          </button>
        </Form>
        <Bottom>
          <Buttom
            name="cancelar"
            type="button"
            onClick={() => setIsOpen()}
            label="Cancelar"
            outline
          />
          <Buttom
            name="salvar"
            onClick={() => formRef.current?.submitForm()}
            label="Salvar"
            isLoading={loading}
          />
        </Bottom>
      </Container>
    </Modal>
  );
};

export default ModalEditUser;
