/* eslint-disable react/require-default-props */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useToast } from '../../../hooks/toast';
import { IPlans } from '../../../pages/Plans';
import { saveManagePlansLog } from '../../../services/logs';
import { useAuth } from '../../../hooks/auth';
import {
  postCreatePlansProvider,
  putPlansProvider,
} from '../../../services/api';
import getValidationErrors from '../../../utils/getVaidationErrors';
import Buttom from '../../forms/Buttom';
import Input from '../../forms/Input';
import TextArea from '../../forms/TextArea';

import Modal from '../Modal';
import { Both } from '../ModalEditItem/styles';

import { Container, Bottom, Colunm, Row, RowContent } from './styles';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  handleInitialState(): void;
  plan?: IPlans;
}

const ModalEditPlan: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  handleInitialState,
  plan,
}) => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Campo Obrigatório'),
          description: Yup.string().required('Campo Obrigatório'),
          maxRent: Yup.number().min(0, 'Valor mínimo é zero'),
          minRent: Yup.number().min(0, 'Valor mínimo é zero'),
        });
        await schema.validate(
          {
            ...data,
            maxRent: Number(data.maxRent || -1),
            minRent: Number(data.minRent || -1),
          },
          {
            abortEarly: false,
          },
        );

        let operation = '';
        let planId = '';

        if (plan && Object.keys(plan).length > 0) {
          operation = 'updated';
          planId = plan.id;

          await putPlansProvider({
            id: plan.id,
            name: data.name,
            description: data.description,
            maxRent: data.maxRent,
            minRent: data.minRent,
          });
        } else {
          operation = 'created';

          await postCreatePlansProvider({
            name: data.name,
            description: data.description,
            maxRent: data.maxRent,
            minRent: data.minRent,
          });
        }

        saveManagePlansLog({
          userId: user.id,
          action: `${operation}_plan`,
          planName: data.name,
        });

        addToast({
          title: 'Sucesso!',
          type: 'success',
          description: `Plano ${
            operation === 'created' ? 'criado ' : 'editado '
          } editado com sucesso!`,
        });

        handleInitialState();
        setIsOpen();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          title: 'Ops...',
          type: 'error',
          description: 'Algo inesperado aconteceu!',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, setIsOpen, handleInitialState, plan],
  );
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <h1>
          {plan && Object.keys(plan).length > 0
            ? `Editar Plano`
            : `Criar Plano`}
        </h1>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={plan}>
          <Colunm style={{ float: 'left' }}>
            <Input
              name="name"
              label="Nome do plano:"
              type="text"
              placeholder="Plano"
            />

            <RowContent>
              <h2>QUANTIDADE DE ALUNOS</h2>
              <Row>
                <Input
                  name="minRent"
                  label="Mínimo:"
                  type="number"
                  placeholder="1000"
                  min="0"
                />
                <Input
                  name="maxRent"
                  label="Máximo:"
                  type="number"
                  placeholder="1001"
                  min="0"
                />
              </Row>
            </RowContent>
          </Colunm>
          <Row style={{ float: 'right' }} className="fix-mt">
            <TextArea
              label="Descrição do plano"
              name="description"
              placeholder="Digite uma descrição"
            />
          </Row>
          <Both />
          <button type="submit" hidden>
            submeter
          </button>
        </Form>
        <Bottom>
          <Buttom
            name="cancelar"
            type="button"
            onClick={() => setIsOpen()}
            label="Cancelar"
            outline
          />
          <Buttom
            name="salvar"
            onClick={() => formRef.current?.submitForm()}
            label="Salvar"
            isLoading={loading}
          />
        </Bottom>
      </Container>
    </Modal>
  );
};

export default ModalEditPlan;
